<template>
  <ValidationProvider v-slot="{ failed, errors }" :rules="validates" :name="name">
    <div class="form-group">
      <div class="custom-control custom-checkbox" :class="{ 'is-invalid': failed }">
        <input :id="id" v-model="isChecked" value="true" type="checkbox" class="custom-control-input" />
        <!-- eslint-disable-next-line -->
        <label class="custom-control-label" :for="id" v-html="label"></label>
      </div>
      <b-form-invalid-feedback v-if="errors.length">
        {{ errors[0] }}
      </b-form-invalid-feedback>
    </div>
  </ValidationProvider>
</template>

<script>

export default {
  props: {
    id:{
      type: String,
      required:true,
    },
    isChecked: {
      required: false,
      type: [Boolean],
      default: null,
    },
    name: {
      type: String,
      default: '',
    },
    label:{
      type: String,
      required: true
    }
  },
  data() {
    return {
      validates: {
        required: true
      },
    }
  },
  watch: {
    isChecked(newValue, oldValue) {
      this.validates.required = !newValue;
    }
  }
}
</script>
<style lang="scss" scoped>
/*Checkbox Terms/Conditions*/
.custom-control {
  &.is-invalid > label:before{
    border-color: red;
  }
  padding-left: 2rem;
  .custom-control-input {
    width: 1.25rem;
    height: 1.25rem;
  }

  .custom-control-label {
    &::before, &::after, {
      top: 0;
      width: 1.25rem;
      height: 1.25rem;
      left: -2rem;
    }
  }
}
</style>
